.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  animation: slide 60s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
}
@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -3500px;
  }
}
