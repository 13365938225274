@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Bestigia";
  src: url("../public/font/Bestigia-Regular.ttf");
}
@layer base {
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../public/font/Mulish/Mulish-Black.ttf");
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("../public/font/Mulish/Mulish-ExtraBold.ttf");
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../public/font/Mulish/Mulish-Bold.ttf");
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../public/font/Mulish/Mulish-SemiBold.ttf");
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../public/font/Mulish/Mulish-Medium.ttf");
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../public/font/Mulish/Mulish-Regular.ttf");
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../public/font/Mulish/Mulish-Light.ttf");
  }
  @font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("../public/font/Mulish/Mulish-ExtraLight.ttf");
  }
}
